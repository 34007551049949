import { reactive, watch, ref, computed, unref } from 'vue'
import moment from 'moment'
import { Moment } from 'moment';
import { PayoutBatchTableData,BUSearchParams,SelectProgramTableData } from "@/views/Payment/BU/types"
import * as API from "@/API/payment"
import { useStore } from 'vuex';
import {callAddPayoutBatchButton, getCreatePayoutBatchDetail, getPayoutCodeTable, getListChainByEntityAndBus} from "@/API/payment";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import useThousands from '@/utils/payment/useThousands'
import {useRoute} from "vue-router";
import router from "@/router";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import {Modal} from "ant-design-vue";
import { ApproveData } from '@/views/Payment/types'
import { message } from 'ant-design-vue';
/*type SearchParams = {
    years: string;
    payoutRound: string;
    BU: string;
    programCode: string;
    programStatus: string;
}*/

const useCreatePayoutCode = () => {
    const states = reactive({
        id:'',
        selectedRowKeys: [], // Check here to configure the default column
        selectedRows: [],
        paymentOverviewId:"",
        payoutYear: '',
        payoutMonth:'',
        payoutRound: '',
        payoutCode:'',
        submitDate: '',
        BU:'',
        programNo: 0,
        programCode:'',
        payoutStatus: 'Created',
        payoutAmoutwoVat:'',
        payoutAmoutwVat:'',
        detailIds:[],
        // approveLines: new Array<string>(),
        approveline:''
    });
    /* watch(() => searchParams.payoutYear, (value) => {
         if (value) {
             searchParams.payoutMonth?.year(value.year())
         }
     })*/

    const columnsPayoutBatch = [
        { title: "NO.", dataIndex: "index",width: 80, customRender: ({index}: {index: number}) => `${index + 1}`},
        { title: "BU", dataIndex: "programBu",width: 80},
        { title: "Program Code", dataIndex: "programCode",key: "payoutCode", width: 100},
        { title: "Program Name", dataIndex: "programName",key: "programNo", width: 80},
        { title: "Dealer No.", dataIndex: "dealerNumber",key: "programNo", width: 80, align: "right", customRender: useThousands()},
        { title: "Payout Amount w/o VAT", dataIndex: "payoutAmountWOVat",customRender: useThousandsAndTwoDecimal(), width: 100, align: "right"},
        { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",customRender: useThousandsAndTwoDecimal(),width: 100, align: "right"}
    ]
    const columnsPendingCreateBatch = [
        { title: "Payout Round", dataIndex: "payoutRound",slots: { customRender: 'payoutRound' }, width: 100},
        { title: "Program Code", dataIndex: "payoutCode", slots: { customRender: 'payoutCode' },width: 100},
        { title: "Program Name", dataIndex: "programNo",slots: { customRender: 'programNo' }, width: 100, align: 'right' },
        { title: "Program BU", dataIndex: "vinNo",slots: { customRender: 'vinNo' },width: 100, align: 'right' },
        { title: "Dealer Number", dataIndex: "payoutAmountWVat",slots: { customRender: 'payoutAmountWVat' },  width: 100, align: 'right'},
        { title: 'Payout Amount w/o VAT', dataIndex: "payoutStatus", width: 120, align: 'center'},
        { title: "Payout Amount w VAT", dataIndex: "submissionDate", width: 120, key: "retailDate", align: 'center'},
        { title: "Lastest Upate Time", dataIndex: "submissionDate", width: 100, key: "retailDate", align: 'center'},
        { title: 'Status', dataIndex: "payoutStatus", width: 100, align: 'center'},
    ]
    const tableDataPayoutBatch = ref<PayoutBatchTableData[]>()
    const tableDataPendingCreateBatch = ref<PayoutBatchTableData[]>()
    const store = useStore()
    const { commit } = useStore()
    const route = useRoute()
    const isSavedFlag = ref<boolean>(false)
    const payoutCodeId = ref<string>()
    const isSubmittedFlag = ref<boolean>(false)
    const isAddFlag = ref<boolean>(false)
    const isRemoveFlag = ref<boolean>(false)


    // 查询approveLines
    const approveLinesRes = ref([])
    const approveLines = computed(() => {
      return approveLinesRes.value
    })
    const getApproveLines = (table: any) => {
      let bus: any[] = []
      const data = unref(table)
      if (data && data.length) {
        bus = Array.from(data.reduce((r: Set<string>, item: any) => {
          r.add(item.programBu)
          return r
        }, new Set<string>()))
      }
      const params = {
        approvalType: 'PayoutApproval',
        bus,
        entity: route.query.entity,
        payoutType: 'DEALER'
      }
      return new Promise((resolve, reject) => {
        getListChainByEntityAndBus(params).then((res: any) => {
            approveLinesRes.value = res
            resolve(res)
          })
      })
      
    }
    const clearApproveLines = () => {
        approveLinesRes.value = []
        states.approveline = ''
    }
    //#region [CreatePayoutBatch按钮接口]
    const getCreatePayoutBatchDetail = (id: string)=>{
        return API.getCreatePayoutBatchDetail({params:{paymentOverviewId:id}}).then((res)=>{
            console.log('打包链接返回结果=====',res)
            console.log('转化后的不含税额=============',toThousandsAndTwoDecimal(res.data.payoutAmountWOVat))
            states.id = res.data.id
            states.payoutRound = res.data.payoutRound
            states.payoutCode = res.data.payoutCode
            states.payoutStatus = res.data.payoutStatus
            states.payoutAmoutwoVat =toThousandsAndTwoDecimal(res.data.payoutAmountWOVat)
            states.payoutAmoutwVat = toThousandsAndTwoDecimal(res.data.payoutAmountWVat)
            states.programNo = res.data.programNo
            states.submitDate = res.data.submitDate.split(' ')[0]
            states.approveline = res.data.approveLine
            tableDataPayoutBatch.value = res.data.programDetailRespVOS
            commit('payment/updateSelectProgramRows',tableDataPayoutBatch.value)
            states.detailIds = res.data.programDetailRespVOS.map((item: any) => item.id)
            // const approveLines = new Set<string>()
            // tableDataPayoutBatch.value?.forEach((item,index,array)=>{
            //     (states.detailIds as any).push(item.id)
            //     approveLines.add(item.programBu)
            // })
            // states.approveLines = Array.from(approveLines)
            if (res.data.payoutStatus == 'Created'){
                isSubmittedFlag.value = false
                isSavedFlag.value = false
                isAddFlag.value = false
                isRemoveFlag.value = false
            }
            else {//置灰
                isSubmittedFlag.value = true
                isSavedFlag.value = true
                isAddFlag.value = true
                isRemoveFlag.value = true
            }
            getApproveLines(tableDataPayoutBatch)
        })
    }
//#endregion
//#region [createPayoutBatch页面的Add按钮接口]
    const visibleSelectProgram = ref<boolean>(false)
    const selectedProgramList = ref<SelectProgramTableData[]>()
    const addPayoutBatch = ()=>{
        const addParams = {
            detailIds: states.detailIds,
            payoutCodeId: route.query.paymentOverviewId,
            payoutRound: states.payoutRound,
            entity: route.query.entity
        }
        console.log('Add 按钮传参数======',addParams)
        API.callAddPayoutBatchButton({...addParams}).then((res)=>{
            visibleSelectProgram.value = true
            if (res.data.length > 0){
                selectedProgramList.value = res.data
            }
            else {
                selectedProgramList.value = []
            }
        })
    }
 //#endregion
    const doSearch = () => {
        const paramsTest = {
            month: '05',
            payoutCode: '2021_May_22_comments_FBAC_VAN',
            payoutRound: '2021_May_22_comments',
            role: 'DEALER',
            status: 'Submitted',
            year: '2021'
        }
        const params = {
            /*year: searchParams.payoutYear.format('YYYY'),
            month: searchParams.payoutMonth ? searchParams.payoutMonth.format('MM') : '',
            payoutCode: searchParams.payoutCode,
            userName: store.state.user.fulnm == 'Sales user'?'OEM IT Admin':store.state.user.fulnm,
            status: ''*/
        }
        //params.status = 'DEALER'
        API.getPayoutCodeTable(paramsTest).then((res: {content: PayoutBatchTableData[]}) => {
            console.log('getPayoutCodeTable=====',res)
            //tableDataPayoutBatch.value = res.content
        })
        //params.status = 'AFCANDLC'
        /* API.getApprovealPaymentTrackingTable(params).then((res: {content: PayoutBatchTableData[]}) => {
             tableDataPendingCreateBatch.value = res.content
         })*/
    }
//#region [createPayoutSaveButton功能]
    const createPayoutSaveButton = ()=> {
        const params = {
            "approveLine": states.approveline,
            "detailIds": states.detailIds,
            "id": route.query.type == 'edit'?route.query.paymentOverviewId:states.paymentOverviewId,
            "payoutAmountWOVat": states.payoutAmoutwoVat,
            "payoutAmountWVat": states.payoutAmoutwVat,
            "payoutRound": states.payoutRound,
            "programCode": states.programCode,
            "programNo": states.programNo,
            "programStatus": 'Created',
            "submitDate": route.query.type == 'edit'?states.submitDate:'',
            entity: route.query.entity
        }
        if (route.query.type == 'edit' && states.submitDate) {
            params.submitDate = moment(states.submitDate).format('yyyy-MM-DD HH:mm:ss')
        }
        API.createPayoutSaveButton(params).then((res)=>{
            console.log('createPayoutSaveButton返回结果：',res)
            if (res.code == '0'){
                isSavedFlag.value = false
                isSubmittedFlag.value = false
                states.payoutCode = res.data.payoutCode
                states.paymentOverviewId = res.data.id
                states.payoutStatus = 'Created'
                Modal.info({
                      title: 'Tips',
                      content: 'Sucessfully saved!'//res.data.message
                    })
            }
            else {
                Modal.info({
                    title: 'Tips',
                    content: res.message//res.data.message
                })
            }
        })
    }
//#endregion
//#region [createPayoutSubmitButton功能]
    const createPayoutSubmitButton = ()=> {
        const params = {
            "approveLine": states.approveline,
            "detailIds": states.detailIds,
            "id": route.query.type == 'edit'?route.query.paymentOverviewId:states.paymentOverviewId,//已经生成id
            "payoutAmountWOVat": states.payoutAmoutwoVat,
            "payoutAmountWVat": states.payoutAmoutwVat,
            "payoutRound": states.payoutRound,
            "programCode": states.programCode,
            "programNo": states.programNo,
            "programStatus": states.payoutStatus,
            "submitDate": '',
            entity: route.query.entity
        }
        if (route.query.type == 'edit' && states.submitDate) {
            params.submitDate = moment(states.submitDate).format('yyyy-MM-DD HH:mm:ss')
        }
        console.log('打包createPayoutSubmitButton的请求的参数为：',params)
        API.createPayoutSubmitButton(params).then((res)=>{
            console.log('createPayoutSubmitButton返回结果：',res)
            if (res.code == '0'){
                isSavedFlag.value = true
                isSubmittedFlag.value = true
                isAddFlag.value = true
                isRemoveFlag.value = true
                states.payoutCode = res.data.payoutCode
                states.payoutStatus = 'Submitted'
                states.submitDate = res.data.submitDate.split(" ")[0]
                Modal.info({
                    title: 'Tips',
                    content: 'Sucessfully submit!'//res.data.message
                })
            }
            else {
                // Modal.info({
                //     title: 'Tips',
                //     content: res.message//res.data.message
                // })
            }
        })
    }

    const approveList = ref<ApproveData[]>()
    const approveVisible = ref(false)
    const approveClick = () => {
        //console.log('states.paymentOverviewId======',states.paymentOverviewId)
        approveList.value = []

        const params = {
            entity: route.query.entity,
            bu: states.approveline,
            approvalType: "PayoutApproval",
            payoutCodeId: states.paymentOverviewId,
            payoutType: "DEALER"
        }
        API.getApprovalPackageByCreated(params).then((res: any) => {
            if (res.code === '0') {
                approveList.value = res.data.map((item: any) => item.nodeList)
                approveVisible.value = true
            } else {
                message.error(res.message)
            }
        })
    }
//#endregion
    return {
        states,
        columnsPayoutBatch,
        columnsPendingCreateBatch,
        tableDataPayoutBatch,
        tableDataPendingCreateBatch,
        doSearch,
        getCreatePayoutBatchDetail,
        addPayoutBatch,
        selectedProgramList,
        visibleSelectProgram,
        isSavedFlag,
        isSubmittedFlag,
        isAddFlag,
        isRemoveFlag,
        createPayoutSaveButton,
        createPayoutSubmitButton,
        getPayoutCodeTable,
        approveLinesRes,
        approveLines,
        getApproveLines,
        clearApproveLines,
        approveVisible,
        approveClick,
        approveList
    }
}
export default useCreatePayoutCode