import { h } from 'vue'
import { toThousands } from './numberFormat'
const useThousands = () => {
    return ({text}: {
        text: number | string;
    }) => {
        return h('span', toThousands(text));
    };
  };
  export default useThousands;
  