
import { defineComponent, reactive, PropType, ref, watch } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from "ant-design-vue";

type Key = ColumnProps['key'];


const columnsAll = [
  {
    title: 'Program Name',
    dataIndex: 'programName',
    key: 'programName',
    width: 150,
    //ellipsis: true
  },
  {
    title: 'Program Code',
    dataIndex: 'programCode',
    key: 'programCode',
    width: 150,
    //ellipsis: true
  }
];

export default defineComponent({
  name: "SelectProgramConfirm",
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    dataSource: {
      required: true,
      type: [], //Object as PropType<any[]>,
      default: ()=>{[]}
    }
  },
  emits: ['update:visible', 'confirm'],
  setup(props, content) {
    const columns = ref<any[]>([]);

    const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: any[]; onChange: Function; fixed: boolean }>({
      onChange: (selectedRowKeys: Key[],selectedRowData: any[] ) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRowData;
      },
      selectedRowData: [],
      selectedRowKeys: [],
      fixed: true
    })

    const handlerConfirm = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('please select data first');
        return;
      }
      console.log('弹出框列表的勾选项为：',rowSelection.selectedRowData)
      content.emit('confirm', rowSelection.selectedRowData);
      content.emit('update:visible', false);
      rowSelection.selectedRowData = [];
      rowSelection.selectedRowKeys = [];
    }

    watch(() => props.visible, (value) => {
      if (value) {
        //const keys = Object.keys(props.dataSource[0]);
        //columns.value = columnsAll.filter(item =>  keys.includes(item.dataIndex));
      }
    })

    return {
      columns,
      columnsAll,
      rowSelection,
      handlerConfirm,
    }
  }
})
