function pow(x: number, y: number): bigint {
  let result = BigInt(1)
  for (let i = 0; i < y; i++) {
    result *= BigInt(x)
  }
  return result
}

function alignPoint(a: bigint, expA: number, b: bigint, expB: number): [bigint, bigint, number] {
  let exponent = 0
  if (expA > expB) {
    exponent = expA
    b *= pow(10, expA - expB)
  } else {
    exponent = expB
    a *= pow(10, expB - expA)
  }
  return [a, b, exponent]
}

function numberToBigInt(number: string, exponent: number): bigint {
  let neg = false
  if (number.length > 0 && number[0] === '-') {
    neg = true
    number = number.slice(1)
  }
  const matches = number.match(/^(\d+)(?:\.(\d*))?$/)
  let result = BigInt(0)
  let int = '0'
  let float = ''
  if (matches && matches.length >= 2) {
    int = matches[1]
    if (matches[2] !== undefined) {
      float = matches[2]
    }
  }
  if (float.length > exponent) {
    float = float.slice(0, exponent)
  }
  result = BigInt(int + float) * pow(10, exponent - float.length)
  if (neg) {
    result *= BigInt(-1)
  }
  return result
}

function bigIntToNumber(number: bigint, exponent: number): string {
  let numStr = number.toString()
  let sign = ''
  if (numStr.length > 0 && numStr[0] === '-') {
    sign = '-'
    numStr = numStr.slice(1)
  }
  const shift = numStr.length - exponent
  if (shift <= 0) {
    numStr = `${sign}0.${'0'.repeat(-shift)}${numStr}`
  } else {
    numStr = `${sign}${numStr.slice(0, shift)}.${numStr.slice(shift)}`
  }
  return numStr
}

export default class Decimal {
  static PRECISION = 20

  private data: string | number | bigint
  v: bigint
  p: number

  constructor(value: string | number | bigint, percision?: number) {
    this.p = percision === undefined ? Decimal.PRECISION : percision
    this.data = value
    if (typeof value === 'string') {
      this.v = numberToBigInt(value.replace(/,/g, ''), this.p)
    } else if (typeof value === 'number') {
      this.v = numberToBigInt(value.toString(), this.p)
    } else if (typeof value === 'bigint') {
      this.v = value * pow(10, this.p)
    } else {
      this.v = BigInt(0)
    }
  }

  private compute(b: Decimal, percision: number, fun: (a: bigint, b: bigint) => bigint): Decimal {
    const [valueA, valueB, maxPercision] = alignPoint(this.v, this.p, b.v, b.p)
    const resultValue = bigIntToNumber(fun(valueA, valueB), maxPercision)
    return new Decimal(resultValue, percision)
  }

  plus(n: Decimal | string | number | bigint, percision?: number): Decimal {
    if (!(n instanceof Decimal)) {
      n = new Decimal(n)
    }
    return this.compute(n, percision ? percision : Decimal.PRECISION, (a, b) => a + b)
  }

  minus(n: Decimal | string | number | bigint, percision?: number): Decimal {
    if (!(n instanceof Decimal)) {
      n = new Decimal(n)
    }
    return this.compute(n, percision ? percision : Decimal.PRECISION, (a, b) => a - b)
  }

  div(n: Decimal | string | number | bigint, percision?: number): Decimal {
    if (!(n instanceof Decimal)) {
      n = new Decimal(n)
    }
    const p = percision ? percision : Decimal.PRECISION
    const [valueA, valueB, maxPercision] = alignPoint(this.v, this.p, n.v, n.p)
    const resultValue = bigIntToNumber(valueA * pow(10, p) / valueB, p)
    return new Decimal(resultValue, p)
  }

  times(n: Decimal | string | number | bigint, percision?: number): Decimal {
    if (!(n instanceof Decimal)) {
      n = new Decimal(n)
    }
    const [valueA, valueB, maxPercision] = alignPoint(this.v, this.p, n.v, n.p)
    const resultValue = bigIntToNumber(valueA * valueB, 2 * maxPercision)
    return new Decimal(resultValue, percision)
  }

  isZero(): boolean {
    return this.v === BigInt(0)
  }

  format(percision: number, showthousands = true, roundUp = true) {
    let value = this.v
    if (this.p > percision) {
      if (roundUp) {
        if (value > 0) {
          value += BigInt(5) * pow(10, this.p - percision - 1)
        } else {
          value -= BigInt(5) * pow(10, this.p - percision - 1)
        }
      }
      value /= pow(10, this.p - percision)
    } else if ( this.p < percision) {
      value *= pow(10, percision - this.p)
    }

    let valueStr = value.toString()
    let sign = ''
    if (valueStr.length > 0 && valueStr[0] === '-') {
      sign = '-'
      valueStr = valueStr.slice(1)
    }
    const shift = valueStr.length - percision
    if (shift <= 0) {
      return `${sign}0.${'0'.repeat(-shift)}${valueStr}`
    } else {
      let int = valueStr.slice(0, shift)
      if (showthousands) {
        int = int.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      }
      const float = valueStr.slice(shift)
  
      return percision === 0 ? `${sign}${int}` : `${sign}${int}.${float}`
    }
  }

  round(percision: number, showthousands = true) {
    return this.format(percision, showthousands)
  }

  fixed(percision: number, showthousands = true) {
    return this.format(percision, showthousands, false)
  }
}