import { useStore } from 'vuex'

export default function usePageSpinning<T>(): (p: Promise<T>) => Promise<T> {
  const { commit } = useStore()
  return async (p: Promise<T>) => {
    commit('updatePageSpinning', true)
    try {
      const result = await p
      return result
    } finally {
      commit('updatePageSpinning', false)
    }
  }
}